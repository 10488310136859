import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BaseButtonComponent } from '../base-button/base-button.component';

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './button.component.html',
  styleUrls: ['../base-button/base-button.component.scss'],
})
export class ButtonComponent extends BaseButtonComponent {
  @Input() type: 'button' | 'submit' = 'button';
}
